<template>
  <b-container fluid>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter Jahr"
          label-for="filter-year-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="filter-year-select"
            v-model="selctedYear"
            :options="year"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter Wettkampfkategorie"
          label-for="filter-eventCategory-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="filter-eventCategory-select"
            v-model="selectedEventCategory"
            :options="eventCategories"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1"> </b-col>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter Kanton"
          label-for="filter-canton-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="filter-canton-select"
            v-model="selectedCanton"
            :options="cantons"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Suche"
          label-for="search-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="search-input"
              v-model="filter"
              type="search"
              placeholder="Suchfunktion"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Suchfilter zurücksetzen</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Suchfiltereinschränkung"
          description="Alles leer lassen um über alle Felder zu suchen"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="eventName">Wettkampfname</b-form-checkbox>
            <b-form-checkbox value="venue">Veranstaltungsort</b-form-checkbox>
            <b-form-checkbox value="eventDate">Datum</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="info(row.item, row.index, $event.target)"
          class="mr-1"
        >
          mehr Infos<!-- Info modal -->
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="fill"
      size="sm"
      class="my-0"
    ></b-pagination>
    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      ok-only
      @hide="resetInfoModal"
    >
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      selctedYear: 0,
      year: [
        { value: 0, text: "Alle Jahre" },
        { value: 1, text: "2021" },
        { value: 2, text: "2020" },
      ],
      selectedCanton: null,
      cantons: [
        // Daten kommen von Alabus
        { value: null, text: "Kanton auswählen" },
        { value: 1, text: "Bern" },
        { value: 2, text: "Zürich" },
        { value: 3, text: "Luzern" },
        { value: 4, text: "Uri" },
        { value: 5, text: "Schwyz" },
        { value: 6, text: "Obwalden" },
        { value: 7, text: "Nidwalden" },
        { value: 8, text: "Glarus" },
        { value: 9, text: "Zug" },
        { value: 10, text: "Freiburg" },
        { value: 11, text: "Solothurn" },
        { value: 12, text: "Basel-Stadt" },
        { value: 13, text: "Basel-Landschaft" },
        { value: 14, text: "Schaffhausen" },
        { value: 15, text: "Appenzell A.Rh" },
        { value: 16, text: "Appenzell I.Rh" },
        { value: 17, text: "Sankt Gallen" },
        { value: 18, text: "Graubünden" },
        { value: 19, text: "Aargau" },
        { value: 20, text: "Thurgau" },
        { value: 21, text: "Tessin" },
        { value: 22, text: "Waadt" },
        { value: 23, text: "Wallis" },
        { value: 24, text: "Neuenburg" },
        { value: 25, text: "Genf" },
        { value: 26, text: "Jura" },
      ],
      selectedEventCategory: null,
      eventCategories: [
        { value: null, text: "Alle Kategorien" },
        { value: 1, text: "offene lokale Ausscheidung" },
        { value: 2, text: "geschlossene lokale Ausscheidung" },
        { value: 3, text: "Kantonalfinal" },
        { value: 4, text: "Schweizer Finale" },
      ],

      items: [
        {
          canton: "Bern",
          eventDate: "2021-10-02",
          eventName: "Jugendlauf Schule Nairolf",
          venue: "Bern",
          eventCategory: "offene lokale Ausscheidung",
        },
        {
          canton: "Bern",
          eventDate: "2021-10-05",
          eventName: "Jugendlauf Schule Albert Schweitzer",
          venue: "Bern",
          eventCategory: "offene Lokaleausscheidung",
        },
        {
          canton: "Bern",
          eventDate: "2021-10-08",
          eventName: "Jugendlauf Schule Johann Wolfgang von Goethe",
          venue: "Bern",
          eventCategory: "Schweizer Finale",
        },
        {
          canton: "St. Gallen",
          eventDate: "2021-10-09",
          eventName: "Jugendlauf Schule Friedrich Schiller",
          venue: "St. Gallen",
          eventCategory: "Kantonalfinale",
        },
        {
          canton: "Bern",
          eventDate: "2021-10-11",
          eventName: "Jugendlauf Schule Theodor Heuss",
          venue: "Bern",
          eventCategory: "Schweizer Finale",
        },
        {
          canton: "Bern",
          eventDate: "2021-10-12",
          eventName:
            "Jugendlauf Schule Heinrich Friedrich Karl vom und zum Stein",
          venue: "Bern",
          eventCategory: "Schweizer Finale",
        },
        {
          canton: "Zug",
          eventDate: "2021-10-12",
          eventName: "Jugendlauf Schule Alexander von Humboldt",
          venue: "Zug",
          eventCategory: "Schweizer Finale",
        },
        {
          canton: "Bern",
          eventDate: "2021-10-18",
          eventName: "Jugendlauf Schule Erich Kästner",
          venue: "Bern",
          eventCategory: "Kantonalfinale",
        },
        {
          canton: "Zürich",
          eventDate: "2021-10-22",
          eventName: "Jugendlauf Schule Anne Frank",
          venue: "Zürich",
          eventCategory: "geschlossene lokale Ausscheidung",
        },
        {
          canton: "Bern",
          eventDate: "2021-10-23",
          eventName: "Jugendlauf Schule Albert Einstein",
          venue: "Bern",
          eventCategory: "Schweizer Finale",
        },
      ],
      fields: [
        {
          key: "canton",
          label: "Kanton",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "eventDate",
          label: "Datum",
          sortable: true,
          class: "text-center",
        },
        {
          key: "eventName",
          label: "Wettkampfname",
          sortable: true,
          class: "text-center",
        },
        {
          key: "venue",
          label: "Veranstaltungsort",
          sortable: true,
          class: "text-center",
        },
        {
          key: "eventCategory",
          label: "Wettkampfkategorie",
          class: "text-center",
        },
        { key: "actions", label: "" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>